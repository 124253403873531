<template>
  <b-container
    fluid
    class="px-0"
  >
    <b-row no-gutters class="justify-content-md-center">
      <b-col
        cols="12"
      >
        <div v-if="!getLoggedIn">
          <login />
        </div>
        <div v-else>
          <Menu />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Login from '@/components/Login.vue'
import Menu from '@/components/Menu.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Main',
    components: {
      Login,
      Menu,
    },
    props: {
    },
    computed: {
      ...mapGetters('general', ['getContractId']),
      getLoggedIn(){
        return this.$auth.check()
      }
    },
    methods: {
        ...mapActions('assets', ['fetchData']),
    },
    watch: {
      getLoggedIn: {
        handler: function () {
          if(this.getLoggedIn){
            this.fetchData({contractId: this.getContractId})
          }
        },
        immediate: true
      },
    },
}
</script>

<style>
</style>
