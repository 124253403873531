import Vue from 'vue'
const state = {
	fetchedPlaces: [],
	fetchedItems: [],
	fetchedDetails: [],
	fetchedProblems: [],
	selectedPlace: null,
	selectedItem: null,
	selectedDetail: null,
	selectedProblem: null,
	selectedHour: null,
	selectedDate: null,
	processStage: "places",
	addedElements: [],
	addedRequirements: [],
}
const mutations = {
	setFetchedPlaces: (state, payload) => (state.fetchedPlaces = payload),
	setFetchedItems: (state, payload) => (state.fetchedItems = payload),
	setFetchedDetails: (state, payload) => (state.fetchedDetails = payload),
	setFetchedProblems: (state, payload) => (state.fetchedProblems = payload),
	setSelectedPlace: (state, payload) => (state.selectedPlace = payload),
	setSelectedItem: (state, payload) => (state.selectedItem = payload),
	setSelectedDetail: (state, payload) => (state.selectedDetail = payload),
	setSelectedProblem: (state, payload) => (state.selectedProblem = payload),
	setSelectedHour: (state, payload) => (state.selectedHour = payload),
	setSelectedDate: (state, payload) => (state.selectedDate = payload),
	setProcessStage: (state, payload) => (state.processStage = payload),
	setAddedElements: (state, payload) => (state.addedElements = payload),
	setAddedRequirements: (state, payload) => (state.addedRequirements = payload),
}
const actions = {
	async fetchPlacesData( { commit }, {asset, project} ) {
		await Vue.axios
			.get(`requests/get_places?asset_id=${asset.asset_id}&asset_type=${asset.asset_type}&asset_number=${asset.asset_number}&project_name=${project.project_name}&project_id=${project.project_id}`)
			.then(response => {
				if(!response.data.length) {
					console.log('No places found')
					return
				}
				commit('setFetchedPlaces', response.data)
			})
			.catch(error => {
				console.log(error)
			})
	},
	async fetchItemsData( { commit }, place ) {
		await Vue.axios
			.get(`requests/items_offline?place_id=${place}`)
			.then(response => {
				if(!response.data) {
					console.log('No Items found')
					return
				}
				commit('setFetchedItems', response.data['items'])
				commit('setProcessStage', 'items')
				commit('general/setLoadingState', false, { root: true })
			})
			.catch(error => {
				console.log(error)
			})
	},
	async fetchDetailsData( { commit }, item ) {
		await Vue.axios
			.get(`requests/details_offline?item_id=${item}`)
			.then(response => {
				if(!response.data) {
					console.log('No Details found')
					return
				}
				commit('setFetchedDetails', response.data['items_details'])
				commit('setProcessStage', 'details')
				commit('general/setLoadingState', false, { root: true })
			})
			.catch(error => {
				console.log(error)
			})
	},
	async fetchProblemsData( { commit}, detail ) {
		await Vue.axios
			.get(`requests/problems_offline?item_detail_id=${detail}`)
			.then(response => {
				if(!response.data) {
					console.log('No Problems found')
					return
				}
				commit('setFetchedProblems', response.data['problems'])
				commit('setProcessStage', 'problems')
				commit('general/setLoadingState', false, { root: true })
			})
			.catch(error => {
				console.log(error)
			})
	},
	resetElementsOnLogOut( { commit } ){
		commit('setFetchedPlaces', [])
		commit('setFetchedItems', [])
		commit('setFetchedDetails', [])
		commit('setFetchedProblems', [])
		commit('setSelectedPlace', null)
		commit('setSelectedItem', null)
		commit('setSelectedDetail', null)
		commit('setSelectedProblem', null)
		commit('setProcessStage', 'places')
		commit('setAddedElements', [])
		commit('setAddedRequirements', [])
	},
}
const getters = {
	getFetchedPlaces: state => state.fetchedPlaces,
	getFetchedItems: state => state.fetchedItems,
	getFetchedDetails: state => state.fetchedDetails,
	getFetchedProblems: state => state.fetchedProblems,
	getSelectedPlace: state => state.selectedPlace,
	getSelectedItem: state => state.selectedItem,
	getSelectedDetail: state => state.selectedDetail,
	getSelectedProblem: state => state.selectedProblem,
	getSelectedHour: state => state.selectedHour,
	getSelectedDate: state => state.selectedDate,
	getProcessStage: state => state.processStage,
	getAddedElements: state => state.addedElements,
	getAddedRequirements: state => state.addedRequirements,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
